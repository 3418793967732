/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { rgba } from "polished"
import { useTheme } from "emotion-theming"
import { IAppTheme } from "../../theme"
import { useEffect, Fragment } from "react"
import Typed from "typed.js"

const makeStyles = (theme: IAppTheme) => ({
  featuredPost: css`
    .typed-cursor {
      font-size: 1.4rem;
  `,
  label: css`
    font-style: italic;
  `,
  statusInput: css`
    border-radius: 0.5rem;
    margin-bottom: 2rem;
    border: 1px solid ${theme.color.primary};
    padding: 0.5rem 1rem 1rem;
    box-shadow: 0 0 0 3px ${rgba(theme.color.secondary, 0.3)};
  `,
  targetTyped: css`
    font-weight: bold;
    font-size: 1.2rem;
    color: ${theme.color.primary};
  `,
})

const MyTypedStrings = () => {
  return (
    <Fragment>
      <span hidden>First, solve the problem. Then write the code.</span>
      <span hidden>
        Without requirements or design, programming is the art of adding bugs to
        an empty text file.
      </span>
      <span hidden>Feel free to contact me.</span>
    </Fragment>
  )
}

const FPWhatsOnMyMind = () => {
  const theme = useTheme<IAppTheme>()
  const styles = makeStyles(theme)

  useEffect(() => {
    // Init Typed.js
    const typed = new Typed("#typedTarget", {
      stringsElement: "#typed-strings",
      startDelay: 0,
      backDelay: 5000,
      typeSpeed: 20,
      backSpeed: 5,
      loop: true,
    })

    return () => {
      typed.destroy()
    }
  })

  return (
    <div css={styles.featuredPost}>
      <label css={styles.label}>What's on my mind:</label>
      <span id="typed-strings">
        <MyTypedStrings />
      </span>
      <div css={styles.statusInput}>
        <span id="typedTarget" css={styles.targetTyped} />
      </div>
    </div>
  )
}

export default FPWhatsOnMyMind
